<template>
    <div class="mx-auto w-full" style="max-width:480px">
        <v-card>
            <v-card-title>
                {{ $t('views.account.changePassword.title') }}
            </v-card-title>
            <v-card-text>
                <v-form ref="form" v-model="isFormValid" lazy-validation @submit="submit">
                    <v-text-field
                        v-model="model.oldPassword"
                        :append-icon="showOldPassword ? 'mdi-eye' : 'mdi-eye-off'"
                        :rules="[rules.required]"
                        :type="showOldPassword ? 'text' : 'password'"
                        :error="hasError('oldPassword')"
                        :error-messages="getError('oldPassword')"
                        :label="$t('views.account.changePassword.oldPassword')"
                        name="oldPassword"
                        outlined
                        @change="resetError('oldPassword')"
                        @click:append="showOldPassword = !showOldPassword"
                    />
                    <v-text-field
                        v-model="model.newPassword"
                        :append-icon="showNewPassword ? 'mdi-eye' : 'mdi-eye-off'"
                        :rules="[rules.required]"
                        :type="showNewPassword ? 'text' : 'password'"
                        :error="hasError('newPassword')"
                        :error-messages="getError('newPassword')"
                        :label="$t('views.account.changePassword.newPassword')"
                        name="newPassword"
                        outlined
                        @change="resetError('newPassword')"
                        @click:append="showNewPassword = !showNewPassword"
                    />
                    <v-text-field
                        v-model="model.confirmPassword"
                        :append-icon="showNewPassword ? 'mdi-eye' : 'mdi-eye-off'"
                        :rules="[rules.required]"
                        :type="showNewPassword ? 'text' : 'password'"
                        :error="hasError('confirmPassword')"
                        :error-messages="getError('confirmPassword')"
                        :label="$t('views.account.changePassword.confirmPassword')"
                        name="confirmPassword"
                        outlined
                        @change="resetError('confirmPassword')"
                        @click:append="showNewPassword = !showNewPassword"
                    />
                    <v-btn type="submit" color="primary" x-large block :loading="isSubmitting" :disabled="isSubmitting">{{ $t('common.save') }}</v-btn>
                </v-form>
            </v-card-text>
        </v-card>
    </div>
</template>

<script>
import _ from 'lodash';
import FormMixins from '@/mixins/FormMixins';
import { updatePersonalPassword } from '@/api';

export default {
    mixins: [FormMixins],
    components: {
    },
    data () {
        return {
            isSubmitting: false,

            // 表单
            showOldPassword: false,
            showNewPassword: false,
            isFormValid: false,
            model: {
                oldPassword: '',
                newPassword: '',
                confirmPassword: ''
            },
            // 验证规则
            rules: {
                required: (value) => (value && Boolean(value)) || this.$t('validation.required')
            },
            returnUrl: null
        };
    },
    watch: {
    },
    methods: {
        async submit (e) {
            e.preventDefault();
            var form = this.$refs.form;
            if (!form.validate()) return;

            this.isSubmitting = true;
            this.resetFormErrors();

            var postData = _.defaults({}, this.model);

            try {
                await updatePersonalPassword(postData);
                this.$toast.info(this.$t('views.account.changePassword.success'));
                form.reset();

                // 跳转回
                if (this.returnUrl) {
                    setTimeout(() => {
                        document.location = this.returnUrl;
                    }, 500);
                }
            } catch (ex) {
                this.setFormErrors(ex);
                this.$toast.error(ex.message);
            } finally {
                this.isSubmitting = false;
            }
        }
    },
    async created () {
        this.isLoading = true;
        this.returnUrl = this.$route.query.returnUrl;
        this.account = await this.$store.dispatch('session/getAccountInfo');
        // this.account = await getAccountInfo();
        this.isLoading = false;
    }
};
</script>
